import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../Assets/Images/logo.svg";
import { connect } from "react-redux";
import { ReactComponent as ProfilePic } from "../../Assets/Images/profile-picture.svg";
import styles from "./style.module.css";
import { useEffect, useRef, useState } from "react";
import FintooLogo from "./images/logo.svg";
import CallBlack from "./Call_Black.png";
import {
  BASE_API_URL,
  DMF_DATAGATHERING_API_URL,
  DMF_GETUSERDETAILS_API_URL,
  DMF_GET_USER_PROFILE_STATE_URL,
  GET_MEMBER_LIST,
  IS_DIRECT,
  CHECK_SESSION,
  DMF_UPDATEBASICDETAILS_API_URL,
  DMF_GETPANSTATUS_API_URL,
  DMF_ADDKYC
} from "../../constants";
import Button from "react-bootstrap/Button";
import commonEncode from "../../commonEncode";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  compareMemberWithUser,
  fetchData,
  fetchEncryptData,
  fetchUserData,
  getItemLocal,
  getMemberId,
  getParentUserId,
  getProfilePercentage,
  getUserId,
  loginRedirectGuest,
  removeMemberId,
  setItemLocal,
  setMemberId,
  removeSlash,
  updateCartCount,
  apiCall,
  removeUserId,
  setFpUserDetailsId
} from "../../common_utilities";

import ProfileSubmenuItem from "./ProfileSubmenuItem";

import { useLocation, useNavigate } from "react-router-dom";
import FintooInlineLoader from "../FintooInlineLoader";
import WhiteOverlay from "../HTML/WhiteOverlay";
import IncompleteRegistration from "../IncompleteRegistration";

const ProfileMenu = ({
  allMembers = [],
  selectedMember = {},
  onMemberClick,
  onClose = () => { },
}) => {
  const location = useLocation();
  const [dis, setDis] = useState();
  const [parentCheck, setParentCheck] = useState();
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let x = location.pathname;



  const logout = () => {
    localStorage.clear();
    dispatch({ type: "LOGGIN_LOGOUT", payload: false });
    navigate(process.env.PUBLIC_URL + "/logout");
  };

  const dropdownChanges = () => {
    let result =
      x.includes("MyCartSelectBank") ||
      x.includes("MyCartAutoPay") ||
      x.includes("MyCartPaymentmode") ||
      x.includes("NetBanking") ||
      x.includes("Mandate") ||
      x.includes("CartUPI") ||
      x.includes("NeftRtgs");
    if (result) {
      setDis(true);
    } else {
      setDis(false);
    }
  };

  useEffect(() => {
    dropdownChanges();
  }, [x]);

  useEffect(() => {
    if (getUserId() != getParentUserId()) {
      setParentCheck(false);
    } else {
      setParentCheck(true);
    }
  }, []);

  const myfun = () => {
    setItemLocal("family", 1);
    localStorage.removeItem("MFSummaryPortfolio");
    localStorage.removeItem('UserPANInfo');
  };
  return (
    <>
      {dis ? (
        <div className={`${styles["submenu-container"]}`}>
          <div className={`${styles["profile-submenu"]}`}>
            <div className={` ${styles.card}`}>
              <div className={`${styles["card-in-2"]} d-flex`}>
                <div className="pe-3">
                  {Object.keys(selectedMember).length > 0 && (
                    <img
                      src={
                        selectedMember.name
                          ? "https://ui-avatars.com/api/?name=" +
                          selectedMember.name.charAt(0) +
                          "&length=1"
                          : "https://ui-avatars.com/api/?name="
                      }
                      width={40}
                      className="rounded-circle"
                    />
                  )}
                </div>
                <div>
                  <p className={styles.profilename}>
                    {Object.keys(selectedMember).length && selectedMember.name}
                  </p>

                  <div>
                    <Link
                      to={`${process.env.PUBLIC_URL}/direct-mutual-fund/profile/dashboard`}
                      className={`${styles["profile-link"]} ps-0`}
                    >
                      <span className="profile_link_span_sec">Profile</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["profile-inner-dv"]}>
              <>
                <Link
                  style={{ textDecoration: "none", color: "#000" }}
                  to={process.env.PUBLIC_URL + "/commondashboard"}
                >
                  <ProfileSubmenuItem
                    text={"Dashboard"}
                    image={require("./images/Dashboard.png")}
                  />
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "#000" }}
                  to={
                    process.env.PUBLIC_URL +
                    "/direct-mutual-fund/portfolio/dashboard"
                  }
                >
                  <ProfileSubmenuItem
                    text={"Portfolio"}
                  // image={require("./images/01_All_stocks_white.png")}
                  />
                </Link>
                <ProfileSubmenuItem
                  text={"Invoices"}
                  image={require("./images/invoice.png")}
                />
                <Link
                  style={{ textDecoration: "none", color: "#000" }}
                  to={process.env.PUBLIC_URL + "/settings/ManageTriggers"}
                >
                  <ProfileSubmenuItem
                    text={"Settings"}
                    image={require("./images/Dashboard.png")}
                  />
                </Link>
              </>

              <Link
                style={{ textDecoration: "none", color: "#000" }}
                to={process.env.PUBLIC_URL + "/logout"}
              >
                <ProfileSubmenuItem text={"Logout"} className="bb-0" />
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className={`${styles["submenu-container"]}`}>
          <div className={`${styles["profile-submenu"]}`}>
            <div className={` ${styles.card}`}>
              <div className={`${styles["card-in-2"]} d-flex`}>
                <div className="pe-3">
                  {Object.keys(selectedMember).length > 0 && (
                    <img
                      src={
                        selectedMember.name
                          ? "https://ui-avatars.com/api/?name=" +
                          selectedMember.name.charAt(0) +
                          "&length=1"
                          : "https://ui-avatars.com/api/?name="
                      }
                      width={40}
                      className="rounded-circle"
                    />
                  )}
                </div>
                <div>
                  <p className={styles.profilename}>
                    {Object.keys(selectedMember).length && selectedMember.name}
                  </p>

                  <div>
                    {getItemLocal("family") ? (
                      ""
                    ) : (
                      <Link
                        to={`${process.env.PUBLIC_URL}/direct-mutual-fund/profile/dashboard`}
                        className={`${styles["profile-link"]} ps-0`}
                        onClick={() => {
                          onClose();
                        }}
                      >
                        <span className="profile_link_span_sec">Profile</span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["profile-inner-dv"]}>
              <div className={styles["scrollable-profile-section"]}>
                {allMembers
                  .filter(
                    (v) =>
                      !(
                        Object.keys(selectedMember).length &&
                        v.id === selectedMember.id
                      )
                  )
                  .map((v) => (
                    <ProfileSubmenuItem
                      key={"memberidp" + v?.id}
                      text={`${(v?.name) ?? ''.split(' ')[0].trim()} ${v?.fdmf_is_minor === "Y" ? "#m" : ""
                        }`}
                      image={
                        v?.name
                          ? "https://ui-avatars.com/api/?name=" +
                          v?.name.charAt(0) +
                          "&length=1"
                          : "https://ui-avatars.com/api/?name="
                      }
                      roundedImage={true}
                      onMemberClick={() => onMemberClick(v)}
                    />
                  ))}
              </div>


              {allMembers.length > 0 && (
                <Link
                  style={{ textDecoration: "none", color: "#000" }}

                  onClick={() => {
                    myfun();
                    window.location.reload();
                  }}
                >

                  {Boolean(getItemLocal("family")) == false && (
                    <ProfileSubmenuItem
                      text={"Family"}
                      image={require("./images/group.png")}
                    />
                  )}
                </Link>
              )}

              <>
                {parentCheck && (
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to={
                      process.env.PUBLIC_URL +
                      "/direct-mutual-fund/profile/AddMembersOptions"
                    }

                  >
                    <ProfileSubmenuItem
                      text={"Add New Member"}
                      image={require("./images/add_member.png")}
                    />
                  </Link>
                )}

                <Link
                  style={{ textDecoration: "none", color: "#000" }}
                  to={process.env.PUBLIC_URL + "/commondashboard"}
                  onClick={() => {
                    setOpenMenu(false)
                  }}
                >
                  <ProfileSubmenuItem
                    text={"Dashboard"}
                    image={require("./images/Dashboard.png")}
                  />
                </Link>

                <Link
                  style={{ textDecoration: "none", color: "#000" }}
                  to={process.env.PUBLIC_URL + "/userflow/invoice"}
                  onClick={() => {
                    setOpenMenu(false)
                  }}
                >
                  <ProfileSubmenuItem
                    text={"Invoices"}
                    image={require("./images/invoice.png")}
                  />
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "#000" }}
                  to={process.env.PUBLIC_URL + "/settings/ManageTriggers"}
                  onClick={() => {
                    setOpenMenu(false)
                  }}
                >
                  <ProfileSubmenuItem
                    text={"Settings"}
                    image={require("./images/Dashboard.png")}
                  />
                </Link>
              </>


              <Link
                style={{ textDecoration: "none", color: "#000" }}
                to={process.env.PUBLIC_URL + "/logout"}
              >
                <ProfileSubmenuItem
                  text={"Logout"}
                  className="bb-0"
                  image={require("./images/logout-88.png")}
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const MainHeader = (props) => {
  const location = useLocation();

  const cartCount = useSelector((state) => state.cartCount);
  const forceReloadCartCount = useSelector((state) => state.forceReloadCartCount);
  const memberChanged = useSelector((state) => state.memberChanged);
  const loggedIn = useSelector((state) => state.loggedIn);

  const [isLoading, setIsLoading] = useState(false);

  const [isProfileIncomplete, setIsProfileIncomplete] = useState(false);
  const [percent, setPercent] = useState("");
  const [pageurl, setPageurl] = useState(false);
  const [urlData, setUrlData] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    dispatch({ type: "LOGGIN_LOGOUT", payload: false });
  };

  const [openMenu, setOpenMenu] = useState(false);
  const [submenu, setSubmenu] = useState("");
  const [openProfile, setOpenProfile] = useState(false);
  // const [reRender, setRerender] = useState("")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [mainData, setMainData] = useState({});
  const [statusData, setStatusData] = useState({});
  const [allMembers, setAllMembers] = useState([]);
  const [parent, setParent] = useState("");
  const [selectedMember, setSelectedMember] = useState({});
  const [tempSelectedMember, setTempSelectedMember] = useState({});
  const isLoadedRef = useRef(false);
  const dispatch = useDispatch();
  const [value, setvalue] = useState("")

  // useEffect(() => {
  //   if (isLoadedRef.current === false) return;
  //   if (selectedMember.parent_user_id === 0) {
  //     removeMemberId();
  //   } else {
  //     setMemberId("" + selectedMember.id);
  //   }
  //   dispatch({
  //     type: "CHANGE_MEMBER",
  //     payload: selectedMember.id,
  //   });
  //   if (location.pathname.indexOf("/commondashboard") > -1) {
  //     window.location.reload();
  //   } else {
  //     window.location = process.env.PUBLIC_URL + "/commondashboard";
  //     // else {
  //     //   navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/profile/dashboard");
  //     // }
  //   }
  // }, [selectedMember]);


  useEffect(() => {
    userProfileState();
    checkUserLoggedIn();
    localStorage.removeItem("combinedDetails")
  }, []);

  useEffect(() => {
    const queryString = window.location.search;

    // Check if the 'update' key is present in the query string
    if (queryString.includes('update=1')) {
      setvalue("update")
    }
  }, [])

  const checkUserLoggedIn = async () => {
    let url = CHECK_SESSION;
    let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
    let respData = await apiCall(url, data, true, false);
    if (respData["error_code"] == "100") {
    } else {
      removeUserId();
      dispatch({ type: "LOGGIN_LOGOUT", payload: false });
    }
  }
  const userProfileState = async () => {
    try {
      // setIsLoading1(true);
      const userdata = await fetchUserData(true);

      let config = {
        method: "POST",
        url: DMF_GET_USER_PROFILE_STATE_URL,
        data: {
          pan: userdata.pan,
          is_direct: IS_DIRECT
        },
      };
      var details1 = await fetchEncryptData(config)
      // Setuserdata(details1);
      if (details1.data.kyc_status == 0 && userdata.pan !== "") {
        let url = DMF_GETPANSTATUS_API_URL

        let data = {
          pan: userdata.pan,
          user_id: getUserId(),
          is_direct: IS_DIRECT,
        };

        let respData = await fetchEncryptData({ url, data, method: "post" });

        if (respData["error_code"] === "100") {
          let name =
            respData["data"]["kyc_name"] !== ""
              ? respData["data"]["kyc_name"]
              : "";

          let v = 1

          if (name) {
            let url = DMF_ADDKYC

            let data = {
              user_id: getUserId(),
              pan: userdata.pan,
              first_name: name,
              kyc_user_name: name,
              kyc_verified: v,
              is_direct: IS_DIRECT,
            };
            let datagatheringinsert = await fetchEncryptData({ url, data, method: "post" });

          }

        }

      }
      const p = await getProfilePercentage(userdata);
      setPercent(p);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (isLoadedRef.current === false) return;
    if (selectedMember.parent_user_id === 0) {
      removeMemberId();
      setFpUserDetailsId("" + selectedMember.fp_user_details_id);
    } else {
      setMemberId("" + selectedMember.id);
      setFpUserDetailsId("" + selectedMember.fp_user_details_id);
    }
    dispatch({
      type: "CHANGE_MEMBER",
      payload: selectedMember.id,
    });
    localStorage.removeItem('UserPANInfo');
    localStorage.removeItem('MFSummaryPortfolio');
    if (location.pathname.indexOf("/commondashboard") > -1) {
      window.location.reload();
    } else if (
      selectedMember.id == getParentUserId() &&
      location.pathname.indexOf("/addmembers") > -1
    ) {
      window.location =
        process.env.PUBLIC_URL + "/direct-mutual-fund/profile/dashboard";
    } else {
      if (location.pathname.indexOf("web/direct-mutual-fund") > -1) {
        if (selectedMember.id != getParentUserId()) {

          if (
            selectedMember.email == parent[0].fdmf_email ||
            selectedMember.mobile == parent[0].mobile
          ) {
            window.location =
              process.env.PUBLIC_URL +
              "/direct-mutual-fund/profile/addmembers?update=1";
          } else if (
            selectedMember.mobile == null ||
            selectedMember.mobile == "" ||
            selectedMember.email == null ||
            selectedMember.email == ""
          ) {
            window.location =
              process.env.PUBLIC_URL +
              "/direct-mutual-fund/profile/addmembers?update=1";
          } else if (
            selectedMember.pan == "" ||
            selectedMember.pan == null ||
            Boolean(selectedMember.incomplete) == true
          ) {
            window.location =
              process.env.PUBLIC_URL + "/direct-mutual-fund/profile";
          } else {
            if (value ?? value) {
              window.location =
                process.env.PUBLIC_URL + "/direct-mutual-fund/profile/dashboard";
            }
            else {
              window.location.reload();

            }
            // window.location.reload();
          }
          // } else if (
          //   selectedMember.pan == "" ||
          //   selectedMember.pan == null ||
          //   Boolean(selectedMember.incomplete) == true
          // ) {
          //   window.location =
          //     process.env.PUBLIC_URL + "/direct-mutual-fund/profile";
        } else if (
          selectedMember.pan != "" ||
          selectedMember.pan != null ||
          Boolean(selectedMember.incomplete) == false
        ) {
          // window.location =
          //   process.env.PUBLIC_URL + "/direct-mutual-fund/profile/dashboard"
          window.location.reload();
        } else {
          // window.location.reload();
          window.location = process.env.PUBLIC_URL + "/commondashboard";
        }
        // } else {
        //   window.location = process.env.PUBLIC_URL + "/commondashboard";
        // }
        // else {
        //   navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/profile/dashboard");
        // }
      }
    }
  }, [selectedMember]);

  useEffect(() => {
    // startAnimation();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > 50) {
      document
        .querySelector("." + styles.header)
        .classList.add(styles.fixedheader);
    } else {
      document
        .querySelector("." + styles.header)
        .classList.remove(styles.fixedheader);
    }
  };

  useEffect(() => {
    // alert(88);
    if (getUserId()) {
      dispatch({ type: "LOGGIN_LOGOUT", payload: true });
      fetchCartCount();
      fetchMembers();
      // if member selected and reload window
      if (getMemberId() != null) {
        console.log("member----------->", getMemberId());
      }
    }
  }, []);

  useEffect(() => {

    if (forceReloadCartCount == true) {
      fetchCartCount();
      dispatch({ type: "FORCE_UPDATE_CART_COUNT", payload: false });
    }
  }, [forceReloadCartCount]);

  useEffect(() => {
    if (allMembers.length > 0) {
      if (getItemLocal("family") || getItemLocal("logged_in")) {
        setSelectedMember({ name: "Family" });
      } else if (getMemberId() != null) {
        setSelectedMember({
          ...allMembers.filter((v) => v.id === Number(getMemberId()))[0],
        });
        var member = allMembers.filter((v) => v.id === Number(getMemberId()))[0];
        setFpUserDetailsId("" + member.fp_user_details_id);
      } else {
        // window.alert("flag")

        setSelectedMember({
          ...allMembers.filter((v) => v.parent_user_id === 0)[0],
        });
        var member = allMembers.filter((v) => v.parent_user_id === 0)[0];
        setFpUserDetailsId("" + member.fp_user_details_id);
      }
    }
  }, [allMembers]);

  const fetchCartCount = async () => {
    var is_direct = IS_DIRECT
    var d = await updateCartCount(is_direct);
    dispatch({ type: "UPDATE_CART_COUNT", payload: d });
  };

  const fetchMembers = async () => {
    try {
      const r = await fetchData({
        url: GET_MEMBER_LIST,
        data: {
          user_id: getParentUserId(),
          // is_direct:IS_DIRECT,// "0",
          is_direct: "1",
        },
        method: "post",
      });
      handleParentdata(r);
      const all = r.data.map((v) => ({
        name: v.NAME ? v.NAME : (v.fdmf_email ? v.fdmf_email : v.user_email),
        id: v.id,
        parent_user_id: v.parent_user_id,
        pan: v.pan,
        mobile: v.mobile,
        email: v.fdmf_email ? v.fdmf_email : v.user_email,
        user_email: v.user_email,
        fp_user_details_id: v.fp_user_details_id,
        fdmf_is_minor: v.minor,
      }));

      setAllMembers([...all]);
      setItemLocal("member", [...all]);

      const currUserId = getUserId()

      const userData = [...all].filter(data => data.id == getUserId())[0]
      if (!Boolean(userData.mobile) || !Boolean(userData.email)) {
        fetchMembersFromUser(currUserId)
      } else {
        setItemLocal("allMemberUser", [...all]);
      }

    } catch (e) { }
  };


  const fetchMembersFromUser = async (userId) => {
    try {
      const r = await fetchData({
        url: GET_MEMBER_LIST,
        data: {
          // user_id: userId,
          user_id: getParentUserId(),
          is_direct: "0",
        },
        method: "post",
      });
      const all = r.data.map((v) => ({
        name: v.NAME ? v.NAME : v.email,
        id: v.id,
        parent_user_id: v.parent_user_id,
        pan: v.pan,
        mobile: v.mobile,
        email: v.email,
      }));

      setItemLocal("allMemberUser", [...all])
    } catch (e) { }
  };

  const handleParentdata = (Rdata) => {
    var getarray1 = Rdata.data.filter((obj) => {
      return obj.parent_user_id == 0;
    });
    setParent(getarray1);
  };

  const handleMenuChange = (v) => {
    v === submenu ? setSubmenu("") : setSubmenu(v);
  };

  const onMemberClick = async (v) => {
    localStorage.removeItem("family");
    localStorage.removeItem("logged_in");
    // window.alert("member")

    if (v._id != getParentUserId()) {
      openNewMember({ ...v, missingDetails: true });
    } else if (
      Boolean(v.pan) == false &&
      location.pathname.indexOf("/commondashboard") === -1
    ) {
      setIsProfileIncomplete(true);
      setTempSelectedMember({ ...v });
    } else {
      var percent = await getProfilePercentage(v);
      if (percent == 100) {
        openNewMember(v);
      } else {
        setIsProfileIncomplete(true);
        setTempSelectedMember({ ...v });
      }
    }
  };


  const openNewMember = (changedMember) => {
    if (changedMember.parent_user_id === 0) {
      removeMemberId();
      setFpUserDetailsId("" + changedMember.fp_user_details_id);
    } else {
      setMemberId("" + changedMember.id);
      setFpUserDetailsId("" + changedMember.fp_user_details_id);
    }

    if (location.pathname.toLowerCase().includes("profile/addminor")) {
      window.location = process.env.PUBLIC_URL + "/direct-mutual-fund/profile";
      return;
    }
    if (location.pathname.toLowerCase().includes("/dashboard/fund")) {
      window.location = process.env.PUBLIC_URL + "/direct-mutual-fund/portfolio/dashboard/";
      return;
    }
    if (location.pathname.toLowerCase().includes("/commondashboard")) {
      window.location.reload();
      return;
    } else if (
      changedMember.id == getParentUserId() &&
      location.pathname.indexOf("/addmembers") > -1
    ) {
      window.location =
        process.env.PUBLIC_URL + "/direct-mutual-fund/profile/dashboard";
    } else {
      if (location.pathname.indexOf("web/direct-mutual-fund") > -1) {
        if (changedMember.id != getParentUserId() && changedMember.fdmf_is_minor !== "Y") {
          if (
            changedMember.email == parent[0].fdmf_email ||
            changedMember.mobile == parent[0].mobile
          ) {
            // console.log("hello1");
            window.location = process.env.PUBLIC_URL + "/direct-mutual-fund/profile/addmembers?update=1";
          } else if (
            changedMember.mobile == null ||
            changedMember.mobile == "" ||
            changedMember.email == null ||
            changedMember.email == ""
          ) {
            // console.log("hello2");
            window.location = process.env.PUBLIC_URL + "/direct-mutual-fund/profile/addmembers?update=1";
          } else if (
            changedMember.pan == "" ||
            changedMember.pan == null ||
            Boolean(changedMember.incomplete) == true
          ) {
            window.location =
              process.env.PUBLIC_URL + "/direct-mutual-fund/profile";
          } else {
            if (value ?? value) {
              window.location =
                process.env.PUBLIC_URL +
                "/direct-mutual-fund/profile/dashboard";
            } else {
              window.location.reload();
            }
          }
        } else if (
          changedMember.pan != "" ||
          changedMember.pan != null ||
          Boolean(changedMember.incomplete) == false
        ) {
          window.location.reload();
        } else {
          window.location = process.env.PUBLIC_URL + "/commondashboard";
        }
      } else {
        window.location.reload();
      }
    }
    // setSelectedMember({ ...v });
    // setOpenProfile(false);
    // window.location.reload(false);
  };

  const toggleLoader = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  useEffect(() => {
    if (
      location.pathname != "/web/direct-mutual-fund/portfolio/dashboard/fund" &&
      tempSelectedMember
    ) {
      dispatch({ type: "SET_TEMP_NAME", payload: "" });
    }
  }, [location]);

  return (
    <>
      <div className={`NDA-Space ${styles.fakeSpace} fakeSpace_rn_k9`}></div>
      <header className={styles.header + " main-header"}>
        <WhiteOverlay show={isLoading} />
        <IncompleteRegistration
          open={isProfileIncomplete}
          onCloseModal={() => {
            setIsProfileIncomplete(false);
          }}
          handleSubmit={() => {
            openNewMember({ ...tempSelectedMember, incomplete: true });
          }}
        />
        <div className="d-block d-lg-none container-fluid">
          <div
            className={` ${styles["mobile-menu"]} d-flex align-items-center justify-content-between`}
          >
            <div
              className={styles["menu-button"]}
              onClick={() => setOpenMenu(true)}
            >
              <div className={styles.hamburger} />
              <div className={styles.hamburger} />
              <div className={styles.hamburger} />
            </div>
            <a
              href={process.env.REACT_APP_PYTHON_URL}
              target="_self"
              className="mobile-logo"
            >
              <Logo />
            </a>
            <div className="d-flex align-items-center">
              <Link
                to={process.env.PUBLIC_URL + "/direct-mutual-fund/mycart"}
                className={` ${styles.cart}`}
              >
                {cartCount > 0 && (
                  <div>
                    <span>{cartCount}</span>
                  </div>
                )}
                <img
                  src={require("./images/cart.png")}
                  width={30}
                />
              </Link>
              <div onClick={() => setOpenProfile(true)}>
                {Object.keys(selectedMember).length > 0 && (
                  <img
                    width={38}
                    className="rounded-circle"
                    src={
                      selectedMember.name
                        ? "https://ui-avatars.com/api/?name=" +
                        selectedMember.name.charAt(0) +
                        "&length=1"
                        : "https://ui-avatars.com/api/?name="
                    }
                  />
                )}
              </div>
            </div>

            <div
              className={`${styles["mobile-menu-wrapper"]} ${openMenu ? styles["active"] : ""
                } `}
              id="hamburger"
            >
              <div className={styles["mobile-menu-block"]}>
                <a
                  onClick={() => setOpenMenu(false)}
                  className={styles["close-menu"]}
                >
                  ×
                </a>
                <ul className={`${styles["mobile-nav"]}`}>
                  <li className={styles["mn-li"]}>
                    <div
                      className="d-block "
                      onClick={() => handleMenuChange("menu1")}
                    >
                      Advisory{" "}
                      <span
                        className={`${styles.aspan} ${styles.mobilespan} ${submenu == "menu1" ? styles["mobilespan-open"] : ""
                          }`}
                      >
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </div>
                    {submenu == "menu1" && (
                      <ul className={styles["submenu"]}>
                        <li>
                          <Link
                            target="_self"
                            onClick={() => {
                              setOpenMenu(false);
                            }}
                            to={
                              process.env.PUBLIC_URL +
                              "/financial-planning-page/"
                            }
                          >
                            Financial Planning
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_self"
                            onClick={() => setOpenMenu(false)}
                            to={
                              process.env.PUBLIC_URL +
                              "/retirement-planning-page/"
                            }
                          >
                            Retirement Planning
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_self"
                            to={
                              process.env.PUBLIC_URL +
                              "/investment-planning-page/"
                            }
                            onClick={() => setOpenMenu(false)}
                          >
                            Investment Planning
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_self"
                            to={process.env.PUBLIC_URL + "/tax-planning-page/"}
                            onClick={() => setOpenMenu(false)}
                          >
                            Tax Planning
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_self"
                            to={process.env.PUBLIC_URL + "/risk-management/"}
                            onClick={() => setOpenMenu(false)}
                          >
                            Risk Management
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li className={styles["mn-li"]}>
                    <div
                      className="d-block"
                      onClick={() => handleMenuChange("menu5")}
                    >
                      Invest{" "}
                      <span className={`${styles.aspan} ${styles.mobilespan}`}>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </div>
                    {submenu == "menu5" && (
                      <ul className={styles["submenu"]}>
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/direct-mutual-funds`}
                            onClick={() => setOpenMenu(false)}
                          >
                            Mutual Fund
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/bond-investment`}
                            onClick={() => setOpenMenu(false)}
                          >
                            Bond
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/stock-advisory`}
                            onClick={() => setOpenMenu(false)}
                          >
                            Domestic Equity
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/international-equity`}
                            onClick={() => setOpenMenu(false)}
                          >
                            International Equity
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => setOpenMenu(false)}
                            to={`${process.env.PUBLIC_URL}/ipo`}
                          >
                            IPO
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>

                  <li className={styles["mn-li"]}>
                    <div
                      className="d-block"
                      onClick={() => handleMenuChange("menu2")}
                    >
                      Tax{" "}
                      <span className={`${styles.aspan} ${styles.mobilespan}`}>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </div>
                    {submenu == "menu2" && (
                      <ul className={styles["submenu"]}>
                        <li>
                          <Link
                            target="_self"
                            onClick={() => setOpenMenu(false)}
                            to={`${process.env.PUBLIC_URL}/itr-file/`}
                          >
                            File your ITR
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_self"
                            onClick={() => setOpenMenu(false)}
                            to={`${process.env.PUBLIC_URL}/nri-taxation/`}
                          >
                            NRI Taxation
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_self"
                            onClick={() => setOpenMenu(false)}
                            to={`${process.env.PUBLIC_URL}/notices/`}
                          >
                            Notices
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_self"
                            to={process.env.PUBLIC_URL + "/tax-planning-page"}
                            onClick={() => setOpenMenu(false)}
                          >
                            Tax Planning
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_self"
                            onClick={() => setOpenMenu(false)}
                            to={`${process.env.PUBLIC_URL}/tax-calculators`}
                          >
                            Tax Calculators
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li className={styles["mn-li"]}>
                    <Link
                      style={{
                        fontWeight: "normal",
                        fontSize: "1.5rem",
                      }}
                      onClick={() => {
                        setOpenMenu(false);
                      }}
                      target="_self"
                      to={`${process.env.PUBLIC_URL}/pricing/`}
                    >
                      Pricing
                    </Link>
                  </li>

                  <li className={styles["mn-li"]}>
                    <div onClick={() => handleMenuChange("menu3")}>
                      Knowledge Base
                      <span className={`${styles.aspan} ${styles.mobilespan}`}>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </div>
                    {submenu == "menu3" && (
                      <ul className={styles["submenu"]}>
                        <li>
                          <a target="_self" href="https://www.fintoo.in/blog/">
                            Blogs
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>

                  <li className={styles["mn-li"]}>
                    <a
                      style={{
                        fontWeight: "normal",
                      }}
                      className="d-block"
                      onClick={() => handleMenuChange("menu2323")}
                    >
                      <span
                        style={{
                          verticalAlign: "textBottom",
                        }}
                      >
                        {" "}
                        <img
                          src={
                            process.env.REACT_APP_STATIC_URL +
                            "media/Header/online_support_black.png"
                          }
                          alt=""
                          style={{ width: "27.5px", verticalAlign: "top" }}
                        />{" "}
                      </span>
                      <span> Help Support</span>
                      <span className={`${styles.aspan} ${styles.mobilespan}`}>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </a>
                    {submenu == "menu2323" && (
                      <ul className={styles["submenu"]}>
                        <li className="pb-0">
                          <span>
                            <img
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/Header/Call_Black.png"
                              }
                              width={20}
                            />{" "}
                          </span>
                          <span>
                            <a
                              className="text-decoration-none text-black ps-1"
                              href="tel:+91-9699 800 600"
                            >
                              +91-9699 800 600
                            </a>
                          </span>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setOpenMenu(false);
                            }}
                            className={`text-decoration-none ${styles.Region} custom-color`}
                            href="https://fintoo.freshdesk.com/support/home"
                          >
                            Customer Help Center
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>

                  <li className={styles["mn-li"]}>
                    <a
                      style={{
                        fontWeight: "normal",
                      }}
                      className="d-block"
                      onClick={() => handleMenuChange("menu123")}
                    >
                      <span
                        style={{
                          verticalAlign: "textBottom",
                        }}
                      >
                        <img
                          src={
                            process.env.REACT_APP_STATIC_URL +
                            "media/Header/global.png"
                          }
                          alt=""
                          style={{ width: "27.5px", verticalAlign: "top" }}
                        />{" "}
                      </span>
                      <span> Change Region</span>
                      <span className={`${styles.aspan} ${styles.mobilespan}`}>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </a>
                    {submenu == "menu123" && (
                      <ul className={styles["submenu"]}>
                        <li>
                          <span style={{ fontSize: "1rem" }}>
                            {" "}
                            Asia Pacific
                          </span>
                          <a
                            className={`text-decoration-none ${styles.Region}`}
                            href="https://www.fintoo.in"
                          >
                            India(English)
                          </a>
                        </li>
                        <li>
                          <span style={{ fontSize: "1rem" }}> Middle East</span>
                          <a
                            className={`text-decoration-none ${styles.Region}`}
                            href="https://fintoo.ae"
                          >
                            UAE(English)
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>

                  {/* mobile */}
                  {loggedIn == false && (
                    <li
                      className={`${styles["link-url"]} ${styles["link-url-mobile"]}`}
                      onClick={() => {
                        loginRedirectGuest();
                      }}
                    >
                      Login
                      {/* </a> */}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-lg-block container-fluid">
          <div className={` ${styles["in-container"]}`}>
            <div className="row align-items-center">
              <div className="col-md-5">
                <a href="/">
                  <Logo />
                </a>
              </div>
              <div className="col-md-7">
                <ul className={styles["main-navigation"]}>
                  <li>
                    <div
                      className={` ${styles["link-url"]} main_header_link_url`}
                      href={process.env.PUBLIC_URL + "#"}
                    >
                      Advisory{" "}
                      <span className={styles.aspan}>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </div>
                    { }
                    <div className={styles["submenu-container"]}>
                      <ul className={styles.submenu}>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/financial-planning-page`}
                            onClick={() => toggleLoader()}
                          >
                            Financial Planning
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/retirement-planning-page`}
                            onClick={() => toggleLoader()}
                          >
                            Retirement Planning
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/investment-planning-page`}
                            onClick={() => toggleLoader()}
                          >
                            Investment Planning
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/tax-planning-page`}
                            onClick={() => toggleLoader()}
                          >
                            Tax Planning
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/risk-management`}
                            onClick={() => toggleLoader()}
                          >
                            Risk Management
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div
                      className={` ${styles["link-url"]} main_header_link_url`}
                      href={process.env.PUBLIC_URL + "#"}
                    >
                      Invest{" "}
                      <span className={styles.aspan}>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div className={styles["submenu-container"]}>
                      <ul className={styles.submenu}>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/direct-mutual-funds`}
                            // to={`${process.env.PUBLIC_URL}/landing-page`}
                            onClick={() => toggleLoader()}
                          >
                            Mutual Fund
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/bond-investment`}
                            onClick={() => toggleLoader()}
                          >
                            Bond
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/stock-advisory`}
                            onClick={() => toggleLoader()}
                          >
                            Domestic Equity
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/international-equity`}
                            onClick={() => toggleLoader()}
                          >
                            International Equity
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/ipo`}
                            onClick={() => toggleLoader()}
                          >
                            IPO
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div
                      className={` ${styles["link-url"]} main_header_link_url`}
                      href={process.env.PUBLIC_URL + "#"}
                    >
                      Tax{" "}
                      <span className={styles.aspan}>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div className={styles["submenu-container"]}>
                      <ul className={styles.submenu}>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/itr-file?utm_service=91&utm_source=26&tags=itr_filing_2024&rm_id=96`}
                            onClick={() => toggleLoader()}
                          >
                            File your ITR
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/nri-taxation/`}
                            onClick={() => toggleLoader()}
                          >
                            NRI Taxation
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/notices/`}
                            onClick={() => toggleLoader()}
                          >
                            Notices
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={process.env.PUBLIC_URL + "/tax-planning-page"}
                            onClick={() => setOpenMenu(false)}
                          >
                            Tax Planning
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={` ${styles["link-url"]} main_header_link_url`}
                            to={`${process.env.PUBLIC_URL}/tax-calculators`}
                            onClick={() => toggleLoader()}
                          >
                            Tax Calculators
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <Link
                      className={` ${styles["link-url"]} main_header_link_url`}
                      to={`${process.env.PUBLIC_URL}/pricing`}
                      onClick={() => toggleLoader()}
                    >
                      Pricing
                    </Link>
                    {/* <a
                      className={styles["link-url"]}
                      href="https://www.fintoo.in/pricing/"
                    >
                      Pricing
                    </a> */}
                  </li>

                  <li>
                    <div
                      className={` ${styles["link-url"]} main_header_link_url`}
                      href={process.env.PUBLIC_URL + "#"}
                    >
                      Knowledge Base{" "}
                      <span className={styles.aspan}>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div className={styles["submenu-container"]}>
                      <ul className={styles.submenu}>
                        <li>
                          <a
                            className={` ${styles["link-url"]} main_header_link_url`}
                            href="https://www.fintoo.in/blog/"
                          >
                            Blogs
                          </a>
                        </li>
                        {/* <li>
                          <a className={styles["link-url"]} href="">
                            Media Room
                          </a>
                        </li>
                        <li>
                          <a className={styles["link-url"]} href="">
                            Bytes
                          </a>
                        </li> */}
                        {/* <li>
                          <Link
                            className={styles["link-url"]}
                            to={`${process.env.PUBLIC_URL}/our-events-page/`}
                            onClick={()=> toggleLoader()}
                          >
                            Events
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </li>
                  {/* <li>
                    <a
                      className={styles["link-url"]}
                      href={process.env.PUBLIC_URL + "#"}
                    >
                      Corporate Care
                      <span className={styles.aspan}>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </a>
                    <div className={styles["submenu-container"]}>
                      <ul className={styles.submenu}>
                        <li>
                          <a className={styles["link-url"]} href="">
                            About CCP
                          </a>
                        </li>
                        <li>
                          <a className={styles["link-url"]} href="">
                            Request Webinar
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li> */}
                  <li className={`pointer ${styles.regionselectordialog}`}>
                    <div className={styles["link-url"]}>
                      <img
                        src={require("./images/customer-support.png")}
                        alt=""
                        style={{ width: "27.5px" }}
                      />
                      {/* 
Customer Help Center */}
                    </div>
                    <div className={styles["submenu-container"]}>
                      <div
                        className={`${styles.submenu}, ${styles.GlobalMenu} ${styles.CallSupport}`}
                      >
                        <div className={`w-100`}>
                          <div className={`${styles.CallOption}`}>
                            <span>

                              <img
                                width={20}
                                src={require("./Call_Black.png")}
                              />{" "}
                            </span>
                            <span>
                              <a
                                className="text-decoration-none text-black ps-1"
                                href="tel:+91-9699 800 600"
                              >
                                +91-9699 800 600
                              </a>
                            </span>
                          </div>
                          <div className={`pt-3 ${styles.CallOption}`}>
                            <a
                              className="text-decoration-none custom-color"
                              // className={styles["link-url"]}
                              target="blank"
                              href="https://fintoo.freshdesk.com/support/home"
                            >
                              Customer Help Center
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className={`pointer ${styles.regionselectordialog}`}>
                    <div className={` ${styles["link-url"]} main_header_link_url`}>
                      <img
                        src={require("./images/global.png")}
                        alt=""
                        style={{ width: "27.5px" }}
                      />
                      {/* Help Center */}
                    </div>
                    <div className={styles["submenu-container"]}>
                      <div
                        className={`${styles.submenu}, ${styles.GlobalMenu}`}
                      >
                        <div className={styles.GlobalLang}>
                          <div className={styles.GlobalText}>
                            Change Region{" "}
                          </div>
                        </div>
                        <div className={`${styles.GloballangMenu}`}>
                          <div className={`${styles.Country}`}>
                            <p>Asia Pacific</p>
                            <div>
                              <a
                                className={`text-decoration-none ${styles.Region} custom-color`}
                                href="https://www.fintoo.in"
                              >
                                India(English)
                              </a>
                            </div>
                          </div>
                          <div className={`${styles.HRline}`}></div>
                          <div className={`${styles.Country}`}>
                            <p>Middle East</p>
                            <div>
                              <a
                                className={`text-decoration-none ${styles.Region} custom-color`}
                                href="https://www.fintoo.ae"
                              >
                                UAE(English)
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* desktop */}
                  {loggedIn === false && (
                    <li
                      className={` ${styles["link-url"]} main_header_link_url`}
                      onClick={() => {
                        loginRedirectGuest();
                      }}
                    >
                      {/* <a
                        target="_self"
                        className="color-blue f-bold"
                        href={
                          process.env.REACT_APP_PYTHON_URL +
                          "/login/?src=T2NRMDc5enFxdjZvUmFsQkl4Z2R1UT09&redirect_uri=OG9RZ0VHNUhxUWRoUW9STjVObTVBK2JSTzlxVThrL25lbThCOStVNENiRlJ2a1hxOEZDQjJRbE0zMDBTMTM3NA%3D%3D"
                        }
                      > */}
                      Login
                      {/* </a> */}
                    </li>
                  )}

                  {loggedIn === true && (
                    <>
                      <li>
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            "/direct-mutual-fund/mycart"
                          }
                          className={`pe-2 ${styles.cart}`}
                        >
                          {cartCount > 0 && (
                            <div>
                              <span className={styles.cartCount}>
                                {cartCount}
                              </span>
                            </div>
                          )}
                          <img
                            src={require("./images/cart.png")}
                            width={30}
                          />
                        </Link>
                      </li>

                      <li>
                        <div className={styles.username}>
                          <div className={styles.usercon}>
                            <strong className={styles.strong}>
                              {Object.keys(selectedMember).length &&
                                selectedMember.name.split(' ')[0].trim()}
                            </strong>
                          </div>
                          <div>
                            <span className={styles.aspan}>
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>
                        <ProfileMenu
                          allMembers={allMembers}
                          selectedMember={selectedMember}
                          onMemberClick={onMemberClick}
                        />
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${styles.profilemenu} ${openProfile ? styles.openprofilemenu : ""
            }`}
        >
          <a
            onClick={() => setOpenProfile(false)}
            className={styles["close-menu"]}
          >
            ×
          </a>
          <ProfileMenu
            allMembers={allMembers}
            selectedMember={selectedMember}
            onMemberClick={onMemberClick}
            onClose={() => {
              setOpenProfile(false);
            }}
          />
        </div>
        {/* Modal  */}
        <Modal
          centered
          show={show}
          onHide={handleClose}
          className={styles["modal-content"]}
        >
          <div className="m-2 DMFPopup">
            <div
              className="popup-container"
              style={{
                padding: "10px 40px",
              }}
            >
              <div className="popup-wrapper text-center">
                <div
                  className="header-box popup-header  d-flex justify-content-center"
                  style={{ top: "-14px" }}
                >
                  <h3>
                    <span
                      className="pink-link"
                      style={{
                        color: "#f0806d",
                        fontSize: ".8em",
                      }}
                    >
                      Disclaimer
                    </span>
                  </h3>
                </div>
                <div className="popup-body">
                  <div className="offer-content mt-2">
                    <div className="row justify-content-center">
                      <div className="col-md-12 mb-3">
                        <p
                          style={{
                            lineHeight: "22px",
                          }}
                        >
                          This is to inform you that by Clicking on the ‘I
                          Agree’, you will be Leaving Fintoo and entering
                          website operated by third parties. Fintoo does not
                          control or endorse such third party website and is not
                          responsible for its content and/or functionality. The
                          use of such website is Subject to the applicable terms
                          and conditions of such third party.
                        </p>
                      </div>
                    </div>
                    <button
                      className={styles["green-btn"]}
                      // className="green-btn d-block"
                      type="button"
                    >
                      <a
                        style={{
                          textDecoration: "none",
                        }}
                        href={process.env.REACT_APP_PYTHON_URL}
                      >
                        I Agree
                      </a>
                    </button>
                    <button
                      className={styles["green-btn"]}
                      style={{
                        margin: "20px auto",
                        padding: " 8px 40px",
                      }}
                      type="button"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </header>
    </>
  );
};

export default MainHeader;
