import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
// import checkmark from "../../Assets/Images/checkmark_white.png";
import checkmark from "../../Assets/Images/checkmark.png";


const GoalSelectionModal = ({
  goalsData,
  isOpenGoalSelectionModal,
  setIsOpenGoalSelectionModal,
  handleOnClose,
  assetData,
  onHide
}) => {
  const defaultGoal = { label: "Automated Linkage", value: 0 };

  console.log("goalsData====>", goalsData);

  const [tempPriorityArray, setTempPriorityArray] = useState([]);

  console.log("tempPriorityArray", tempPriorityArray);

  const updateGoals = () => {
    handleOnClose({ selectedGoals: tempPriorityArray });
    setIsOpenGoalSelectionModal(false);
  };

  const reset = () => {
    setTempPriorityArray([]);
  };

  useEffect(() => {
    try {
      setTempPriorityArray(assetData.linked_goals_id??[]);
    } catch {
      setTempPriorityArray([0]);
    }
  }, [assetData?.linked_goals_id]);

  console.log("nil1111", tempPriorityArray, assetData, assetData.linked_goals_id);

  return (
    <>
      <Modal show={isOpenGoalSelectionModal} className="popupmodal" centered onHide={()=> {
        onHide();
      }}>
        <Modal.Header className="ModalHead">
          <div className="d-flex" style={{ height: "26px" }}>
            <div className="w-100"> Link This Asset To Goals</div>

            <div className="">
              <img
                onClick={() => {
                  setIsOpenGoalSelectionModal(false);
                  onHide();
                }}
                className="pointer"
                src="https://images.minty.co.in/static/assets/img/cancel_white.svg"
                width={40}
              />
            </div>
          </div>
          <small style={{ fontSize: "14px", fontWeight: "lighter" }}>
            You can set the priority as per your goals
          </small>
          <br></br>
        </Modal.Header>
        <Modal.Body>
          <div className="table-container">
            <table className="goal-table">
              <thead>
                <tr className="goal-table-header">
                  <td>Priority</td>
                  <td>Goal Name</td>
                </tr>
              </thead>
              <tbody className="dropdown-content">
                {goalsData.map((goal, index) => (
                  <tr
                    className={`${tempPriorityArray.includes(goal.value) ? "goalSelected" : ""}`}
                    onClick={() => {
                      if (goal.value == defaultGoal.value) {
                        setTempPriorityArray([goal.value]);
                      } else {
                        setTempPriorityArray((prev) => {
                          if (tempPriorityArray.includes(goal.value)) {
                            return tempPriorityArray.filter(
                              (v) => v != goal.value && v != defaultGoal.value
                            );
                          } else {
                            return [...prev, goal.value].filter(
                              (v) => v != defaultGoal.value
                            );
                          }
                        });
                      }
                    }}
                  >
                    <td width={50} className="prioritytd">
                      {goal.value == defaultGoal.value &&
                        tempPriorityArray.includes(defaultGoal.value) && (
                          <div className="circle">
                            <img
                              className={`checkmark-img`}
                              src={checkmark}
                              width={40}
                            />
                          </div>
                        )}
                      {!(
                        goal.value == defaultGoal.value &&
                        tempPriorityArray.includes(defaultGoal.value)
                      ) && (
                        <div className="circle">
                          {tempPriorityArray.indexOf(goal.value) + 1 > 0 &&
                            tempPriorityArray.indexOf(goal.value) + 1}
                        </div>
                      )}
                    </td>
                    <td width={100} className="goalName">
                      {goal.label}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="button"
            className="btn LInkOTPBTn"
            onClick={() => {
              updateGoals();
            }}
          >
            Done
          </button>
          <button
            type="button"
            className="btn LInkOTPBTn"
            onClick={() => {
              reset();
            }}
          >
            Reset
          </button>
        </div>
      </Modal>
    </>
  );
};

export default GoalSelectionModal;
