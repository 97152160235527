import React, { useEffect, useState } from "react";
import InternationalEquityAdvantagesSection from "../components/HTML/InternationalEquity/AdvantagesSection";
import InternationalEquityHeaderSection from "../components/HTML/InternationalEquity/InternatinoalEquityHeaderSection";
import InternationalEquityContactForm from "../components/HTML/InternationalEquity/InternationalEquityContactSection";
import WhyInvestSection from "../components/HTML/InternationalEquity/WhyInvestSection";
import FaqSection from "../components/HTML/InternationalEquity/FaqSection";
import Fullpage from "../components/Layout/Fullpage";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import ThankyouSection from "../components/ThankyouSection";
import AppointmentBox from "../components/Pages/Calendly";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
function InternationalEquity() {
  const [show, SetShow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageurl, setPageurl] = React.useState();
  const [utmSource, setUtmSource] = useState(26);
  const [tagval, setTagval] = useState(null);
  useEffect(() => {
    function extractParametersFromURL() {
      // const urlSearchParams = new URLSearchParams(new URL(url).search);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const utmSource = urlSearchParams.get('utm_source');
      const tagval = urlSearchParams.get('tags');
      setPageurl(location.pathname);
      setUtmSource(utmSource);
      setTagval(tagval);
    }
    extractParametersFromURL();
    window.addEventListener('popstate', extractParametersFromURL);
    return () => {
      window.removeEventListener('popstate', extractParametersFromURL);
    };
  }, []);
  useEffect(() => { }, []);

  return (
    <Fullpage>
      <div style={{ backgroundColor: "white" }}>
        <InternationalEquityHeaderSection />

        {/* <InternationalEquityContactForm serviceType={servicesTypes.internationalEquity} onSuccess={() => SetShow(true)} /> */}

        <InternationalEquityAdvantagesSection />

        <section
          style={{
            backgroundColor: "#e8f6fc",
          }}
        >
          <WhyInvestSection />
        </section>

        {show && <ThankyouSection onClose={() => SetShow(false)} />}
        <section id="calendlySection">
          {utmSource && tagval ? (
            <AppointmentBox
              extraParams={{ "utm_source": utmSource, "service": 98 }}
              eventCode={tagval}
              serviceName="Direct International Equity"
              eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-international-equity?hide_event_type_details=1"}
              planId="49"
            />
          ) : (
            <AppointmentBox
              eventCode={'Callback_mintyApp_90'}
              serviceName="Direct International Equity"
              eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-international-equity?hide_event_type_details=1"}
              planId="49"
            />
          )}
        </section>

        {/* <AppointmentBox eventCode={'Callback_mintyApp_90'} serviceName="Direct International Equity" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-international-equity?hide_event_type_details=1" /> */}
        <FaqSection />
      </div>
    </Fullpage>
  );
}

export default InternationalEquity;
