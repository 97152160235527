import React, { useEffect, useState } from "react";
// import ReactDOM from "react-dom";
import Pan from "../Pages/ProfileCompoenents/Profile/Pan";
import Birth from "../Pages/ProfileCompoenents/Profile/DOB";
import AadharAddress from "../Pages/ProfileCompoenents/Profile/AadharAddress";
import Occupation from "../Pages/ProfileCompoenents/Profile/Occupation";
import NomineeDetails from "../Pages/ProfileCompoenents/Profile/NomineeDetails";
import MaritalStatus from "../Pages/ProfileCompoenents/Profile/MaritalStatus";
import Bank from "../Pages/ProfileCompoenents/Profile/Bank";
import BankCheque from "../Pages/ProfileCompoenents/Profile/BankCheque";
import ConfirmBank from "../Pages/ProfileCompoenents/Profile/ConfirmBank";
import UploadPan from "../Pages/ProfileCompoenents/Profile/UploadPan";
// import UploadPan from "../Pages/ProfileCompoenents/Profile/UploadPan";
import UploadVideo from "../Pages/ProfileCompoenents/Profile/UploadVideo";
import UploadPhoto from "../Pages/ProfileCompoenents/Profile/UploadPhoto";
import UploadSignature from "../Pages/ProfileCompoenents/Profile/UploadSignature";
import UploadAadharFront from "../Pages/ProfileCompoenents/Profile/UploadAadharFront";
import UploadAadharBack from "../Pages/ProfileCompoenents/Profile/UploadAadharBack";
import AadharDocument from "../Pages/ProfileCompoenents/Profile/AadharDocument";
import FatcaAll from "../Pages/ProfileCompoenents/Profile/FatcaAll";
import FatcaAdd from "../Pages/ProfileCompoenents/Profile/FatcaAdd";
import { useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";
import MainLayout from "../Layout/MainLayout";
import {
  apiCall,
  fetchData,
  fetchEncryptData,
  getParentUserId,
  getUserId,
  loginRedirectGuest,
  memberId,
} from "../../common_utilities";
import { ToastContainer } from "react-toastify";
import {
  DMF_DATAGATHERING_API_URL,
  DMF_GET_USER_PROFILE_STATE_URL,
  GET_MEMBER_LIST,
  DMF_NOMINEESTATUS,
  IS_DIRECT,
} from "../../constants";
import commonEncode from "../../commonEncode";
import MinorsDetails from "../../Pages/DMF/ProfileInsider/AddMembers/AddMinorForms/minorsDetails";
import MinorsOtherDetails from "../../Pages/DMF/ProfileInsider/AddMembers/AddMinorForms/minorsOtherDetails";
import AgeProof from "../../Pages/DMF/ProfileInsider/AddMembers/AddMinorForms/ageProof";
import UploadBankDetails from "../../Pages/DMF/ProfileInsider/AddMembers/AddMinorForms/uploadBankDetails";
import LegalGuardianProof from "../../Pages/DMF/ProfileInsider/AddMembers/AddMinorForms/legalGuardianProof";

function Profile(props) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPanel, setShowPanel] = useState("");
  const [mainData, setMainData] = useState({});
  const [statusData, setStatusData] = useState({});
  const [documentName, setDocumentName] = useState("");
  const [skipInit, setSkipInit] = useState(true);
  const [profileState, setProfileState] = useState("");
  const [allMembers, setAllMembers] = useState([]);
  const [parent, setParent] = useState("");
  const [status, setnomineestatus] = useState("");

  const storedMinorDetails = localStorage.getItem("combinedDetails");
  const minorDetailsObject = JSON.parse(storedMinorDetails);

  let value = 0;
  if (mainData.is_minor === "Y") {
    value = 25;
    if (mainData.bse_reg === "Y") {
      value = 50;
      if (mainData.fatca_status === "PASS") {
        value = 75;
        if (mainData.aof_status === "Y") {
          value = 100;
        }
      }
    }
  }

  useEffect(() => {
    // console.log("shree",mainData)
    // console.log(status,statusData.bse_aof_status ,"dddddddd")
    console.log("nil543", statusData);

    if (skipInit == false) return;
    if (parent.length == 0) return;
    dispatch({ type: "PROFILE_BACK", payload: true });
    if (Object.keys(mainData).length) {
      var sp = "Pan";
      if (mainData.user_id != getParentUserId() && mainData.is_minor !== "Y") {
        if (
          Boolean(mainData.mobile == parent[0].mobile) ||
          Boolean(mainData.email == parent[0].fdmf_email)
        ) {
          window.location =
            process.env.PUBLIC_URL +
            "/direct-mutual-fund/profile/addmembers?update=1";
          return;
        }
      }
      if (
        (mainData.mobile == "" || mainData.email == "") &&
        mainData.is_minor !== "Y"
      ) {
        window.location =
          process.env.PUBLIC_URL +
          "/direct-mutual-fund/profile/addmembers?update=1";
        return;
      }
      if (mainData.is_minor !== "Y") {
        if (mainData.pan.trim() == "") {
          setShowPanel("Pan");
          return;
        }
        if (mainData.dob == "") {
          setShowPanel("Birth");
          return;
        }
        if (mainData.address.trim() == "") {
          setShowPanel("AadharAddress");
          return;
        }
        if (
          mainData.income_slab.trim() == "" ||
          mainData.occupation == "" ||
          mainData.marital_status.trim() == ""
        ) {
          setShowPanel("Occupation");
          return;
        }
        if (
          mainData.residential_status == "" ||
          mainData.user_holding_nature == "" ||
          mainData.politically_exposed == ""
        ) {
          setShowPanel("FatcaAll");
          return;
        }
        if (
          mainData.residential_status * 1 == 2 &&
          mainData.user_nri_address == ""
        ) {
          setShowPanel("FatcaAdd");
          return;
        }
        if (mainData.nominee_status == "N") {
          setShowPanel("NomineeDetails");
          return;
        }
        if (statusData.bank_details == "No" || statusData.bse_status == "No") {
          setShowPanel("Bank");
          return;
        }
        if (
          (mainData.residential_status == 2 ||
            mainData.residential_status == 3) &&
          statusData.bse_status == "Yes" &&
          statusData.fatca_status == "Yes" &&
          statusData.bank_details == "Yes"
        ) {
          setShowPanel("Bank");
          return;
        }

        if (statusData.kyc_verified == "No") {
          setShowPanel("UploadPan");
          return;
        }

        if (statusData.bse_status == "No") {
          if (mainData.pan.trim() != "") {
            dispatch({ type: "PROFILE_BACK", payload: false });
          }
          setShowPanel("Birth");
          return;
        }

        if (statusData.kyc_verified == "No") {
          if (statusData.bse_status == "Yes") {
            dispatch({ type: "PROFILE_BACK", payload: false });
          }
          setShowPanel("UploadPan");
          return;
        }
        if (statusData.bse_aof_status == "No") {
          if (statusData.kyc_verified == "Yes") {
            dispatch({ type: "PROFILE_BACK", payload: false });
          }
          setShowPanel("UploadSignature");
          return;
        } else {
          window.location.href =
            process.env.PUBLIC_URL + "/direct-mutual-fund/funds/all";
        }
      } else {
        if (mainData.dob == "") {
          setShowPanel("DETAILS");
          return;
        }
        if (statusData.bank_details == "No" && statusData.bse_status == "No") {
          setShowPanel("Bank");
          return;
        }
        if (statusData.bse_status == "No") {
          setShowPanel("Bank");
          return;
        }
        if (statusData.bse_aof_status === "No") {
          if (mainData.guardian_relation === "legal guardian") {
            setShowPanel("LEGALGUARDIANPROOF");
          } else {
            setShowPanel("AGEPROOF");
          }
        }
      }
    }
  }, [mainData, parent]);

  useEffect(() => {
    if (getUserId() == null) {
      loginRedirectGuest();
    } else {
      document.body.classList.add("page-profile");
      // if (searchParams.get("s")) {
      //   setShowPanel(searchParams.get("s"));
      // } else {
      //   setShowPanel('Pan');
      // }
      fetchUserData();
      nomineestatus();
      fetchMembers();
      setTimeout(() => {
        setSkipInit(false);
      }, 1000);
    }
  }, [status]);

  const handlePrevious = (v) => {
    console.log("vvvv1", v);
    fetchUserData();
    dispatch({ type: "PROFILE_BACK", payload: true });
    if (mainData.is_minor !== "Y") {
      if (mainData.pan.trim() == "" && v == "Pan") {
        setShowPanel("Pan");
        return;
      }
      if (statusData.bse_status == "No" && v == "Birth") {
        if (mainData.pan.trim() != "") {
          dispatch({ type: "PROFILE_BACK", payload: false });
        }
        setShowPanel("Birth");
        return;
      }

      if (statusData.bse_status == "No" && v == "AadharAddress") {
        setShowPanel("AadharAddress");
        return;
      }
      if (statusData.bse_status == "No" && v == "MaritalStatus") {
        setShowPanel("MaritalStatus");
        return;
      }
      if (statusData.bse_status == "No" && v == "Occupation") {
        setShowPanel("Occupation");
        return;
      }
      if (statusData.bse_status == "No" && v == "FatcaAll") {
        setShowPanel("FatcaAll");
        return;
      }
      if (statusData.bse_status == "No" && v == "FatcaAdd") {
        if (
          parseInt(mainData.residential_status) == 2 ||
          parseInt(mainData.residential_status) == 3
        ) {
          setShowPanel("FatcaAdd");
          return;
        } else {
          setShowPanel("NomineeDetails");
          return;
        }
      }
      if (statusData.bse_status == "No" && v == "NomineeDetails") {
        setShowPanel("NomineeDetails");
        return;
      }
      if (statusData.bse_status == "No" && v == "Bank") {
        if (statusData.bank_details == "Yes") {
          setShowPanel("UploadPan");
          return;
        } else {
          setShowPanel("Bank");
          return;
        }
      }

      if (statusData.bse_status == "No" && v == "BankCheque") {
        if (
          mainData.residential_status == 2 ||
          mainData.residential_status == 3
        ) {
          setShowPanel("BankCheque");
          return;
        } else {
          setShowPanel("ConfirmBank");
          return;
        }
      }
      if (statusData.bse_status == "No" && v == "ConfirmBank") {
        setShowPanel("ConfirmBank");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "UploadPan") {
        if (statusData.bse_status == "Yes") {
          dispatch({ type: "PROFILE_BACK", payload: false });
        }
        setShowPanel("UploadPan");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "AadharDocument") {
        setShowPanel("AadharDocument");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "UploadAadharFront") {
        setShowPanel("UploadAadharFront");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "UploadAadharBack") {
        setShowPanel("UploadAadharBack");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "UploadPhoto") {
        setShowPanel("UploadPhoto");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "UploadVideo") {
        setShowPanel("UploadVideo");
        return;
      }
      if (statusData.bse_aof_status == "No" && v == "UploadSignature") {
        if (statusData.kyc_verified == "Yes") {
          dispatch({ type: "PROFILE_BACK", payload: false });
        }
        setShowPanel("UploadSignature");
        return;
      }
    } else {
      if (v == "UPLOADBANKDETAILS") {
        setShowPanel("UPLOADBANKDETAILS");
        return;
      }
      if (v == "ConfirmBank") {
        setShowPanel("ConfirmBank");
        return;
      }
      if (mainData.dob && v == "DETAILS") {
        setShowPanel("DETAILS");
        return;
      }
      if (
        statusData.bank_details == "No" &&
        statusData.bse_status == "No" &&
        v == "Bank"
      ) {
        setShowPanel("Bank");
        return;
      }
      if (statusData.bse_status == "No" && v == "Bank") {
        setShowPanel("Bank");
        return;
      }
      if (statusData.bse_status == "Yes" && v == "Bank") {
        setShowPanel("Bank");
        return;
      }
      if (statusData.bse_status == "No" && v == "ConfirmBank") {
        setShowPanel("ConfirmBank");
        return;
      }

      if (v == "OTHERDETAILS") {
        setShowPanel("OTHERDETAILS");
        return;
      }
      if (statusData.bse_aof_status === "No" && v == "AGEPROOF") {
        setShowPanel("AGEPROOF");
        return;
      }
      // if (statusData.bse_aof_status === "No" && v == "LEGALGUARDIANPROOF") {
      //   setShowPanel("LEGALGUARDIANPROOF");
      //   return;
      // }
    }
    // else{
    //   window.location.href = process.env.PUBLIC_URL+'/direct-mutual-fund/funds/all';
    // }

    // setShowPanel(v);
  };
  const handleNext = (v) => {
    fetchUserData();
    dispatch({ type: "PROFILE_BACK", payload: true });
    if (mainData.is_minor !== "Y") {
      if (mainData.pan.trim() == "" && v == "Pan") {
        setShowPanel("Pan");
        return;
      }
      if (statusData.bse_status == "No" && v == "Birth") {
        if (mainData.pan.trim() != "") {
          dispatch({ type: "PROFILE_BACK", payload: false });
        }
        setShowPanel("Birth");
        return;
      }

      if (statusData.bse_status == "No" && v == "AadharAddress") {
        setShowPanel("AadharAddress");
        return;
      }
      if (statusData.bse_status == "No" && v == "MaritalStatus") {
        setShowPanel("MaritalStatus");
        return;
      }
      if (statusData.bse_status == "No" && v == "Occupation") {
        setShowPanel("Occupation");
        return;
      }
      if (statusData.bse_status == "No" && v == "FatcaAll") {
        setShowPanel("FatcaAll");
        return;
      }
      if (statusData.bse_status == "No" && v == "FatcaAdd") {
        setShowPanel("FatcaAdd");
        return;
      }
      if (statusData.bse_status == "No" && v == "NomineeDetails") {
        setShowPanel("NomineeDetails");
        return;
      }
      if (statusData.bse_status == "No" && v == "Bank") {
        if (
          statusData.bank_details == "Yes" &&
          statusData.kyc_verified == "No"
        ) {
          setShowPanel("UploadPan");
          return;
        } else if (
          statusData.kyc_verified == "Yes" &&
          statusData.bank_details == "Yes"
        ) {
          setShowPanel("UploadSignature");
          return;
        } else {
          setShowPanel("Bank");
          return;
        }
      }
      if (statusData.bse_status == "No" && v == "BankCheque") {
        if (
          parseInt(mainData.residential_status) == 2 ||
          parseInt(mainData.residential_status) == 3
        ) {
          setShowPanel("BankCheque");
          return;
        } else {
          setShowPanel("ConfirmBank");
          return;
        }
      }
      if (statusData.bse_status == "No" && v == "ConfirmBank") {
        setShowPanel("ConfirmBank");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "UploadPan") {
        if (statusData.bse_status == "Yes") {
          dispatch({ type: "PROFILE_BACK", payload: false });
        }

        setShowPanel("UploadPan");
        return;
      } else if (statusData.kyc_verified == "Yes") {
        dispatch({ type: "PROFILE_BACK", payload: false });
        setShowPanel("UploadSignature");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "AadharDocument") {
        setShowPanel("AadharDocument");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "UploadAadharFront") {
        setShowPanel("UploadAadharFront");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "UploadAadharBack") {
        setShowPanel("UploadAadharBack");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "UploadPhoto") {
        setShowPanel("UploadPhoto");
        return;
      }
      if (statusData.kyc_verified == "No" && v == "UploadVideo") {
        setShowPanel("UploadVideo");
        return;
      }
      if (statusData.bse_aof_status == "No" && v == "UploadSignature") {
        if (statusData.kyc_verified == "Yes") {
          dispatch({ type: "PROFILE_BACK", payload: false });
        }
        setShowPanel("UploadSignature");
        return;
      }
      if (statusData.bse_aof_status == "No") {
        if (statusData.kyc_verified == "No") {
          dispatch({ type: "PROFILE_BACK", payload: false });
        }

        setShowPanel("UploadPan");
        return;
      } else {
        window.location.href =
          process.env.PUBLIC_URL + "/direct-mutual-fund/funds/all";
      }
    } else {
      if (v == "UPLOADBANKDETAILS") {
        setShowPanel("UPLOADBANKDETAILS");
        return;
      }
      if (mainData.dob && v == "DETAILS") {
        setShowPanel("DETAILS");
        return;
      }
      if (
        statusData.bank_details == "No" &&
        statusData.bse_status == "No" &&
        v == "Bank"
      ) {
        setShowPanel("Bank");
        return;
      }
      if (statusData.bse_status == "No" && v == "Bank") {
        setShowPanel("Bank");
        return;
      }
      if (v == "Bank") {
        setShowPanel("Bank");
        return;
      }
      if (v == "ConfirmBank") {
        setShowPanel("ConfirmBank");
        return;
      }
      if (v == "OTHERDETAILS") {
        setShowPanel("OTHERDETAILS");
        return;
      }
      if (statusData.bse_aof_status === "No" && v == "AGEPROOF") {
        setShowPanel("AGEPROOF");
        return;
      }
      if (statusData.bse_aof_status === "No" && v == "LEGALGUARDIANPROOF") {
        setShowPanel("LEGALGUARDIANPROOF");
        return;
      }
    }
    // setShowPanel(v);
  };

  const onDocumentSelect = (v) => {
    setDocumentName(v);
  };

  const userProfileState = async () => {
    try {
      if (Object.keys(mainData).length == 0) return;
      if (mainData.pan.trim() == "") {
        setProfileState(0);
      } else {
        let config = {
          method: "POST",
          url: DMF_GET_USER_PROFILE_STATE_URL,
          data: {
            pan: mainData.pan,
          },
        };
        var res = await fetchEncryptData(config);
        setProfileState(res.data.profile_status);
      }
    } catch (e) {
      console.error("Error --->", e);
    }
  };
  const nomineestatus = async () => {
    try {
      var res = await apiCall(DMF_NOMINEESTATUS, {
        user_id: getUserId(),
        is_direct: IS_DIRECT,
      });
      setnomineestatus(res.message);
      console.log(res.message, "data");
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMembers = async () => {
    try {
      // console.log("data 7777777");
      const r = await fetchData({
        url: GET_MEMBER_LIST,
        data: {
          user_id: getParentUserId(),
          // is_direct:IS_DIRECT,// "0",
          is_direct: "1",
        },
        method: "post",
      });
      handleParentdata(r);
      const all = r.data.map((v) => ({
        name: v.NAME ? v.NAME : v.fdmf_email,
        id: v.id,
        parent_user_id: v.parent_user_id,
        pan: v.pan,
        mobile: v.mobile,
        email: v.fdmf_email,
      }));
      setAllMembers([...all]);
    } catch (e) {}
  };

  const handleParentdata = (Rdata) => {
    var getarray1 = Rdata.data.filter((obj) => {
      return obj.parent_user_id == 0;
    });
    setParent(getarray1);
  };

  useEffect(() => {
    userProfileState();
  }, [showPanel]);

  const fetchUserData = async () => {
    try {
      if (getUserId() == null) {
        // loginRedirectGuest();
        return;
      }
      var payload = {
        url: DMF_DATAGATHERING_API_URL,
        method: "post",
        data: { user_id: "" + getUserId() },
      };
      var res = await fetchEncryptData(payload);

      setMainData(res.data);
      setStatusData(res.user_alldata_status);
      // localStorage.setItem("user", JSON.stringify(res.data));
      // var userData = localStorage.getItem('user');
    } catch (e) {
      console.error(e);
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: e.toString(), type: "error" },
      });
    }
  };

  return (
    <MainLayout>
      <div className="Profile">
        <ToastContainer />
        <div className="container">
          <div className="profile-container">
            <div>
              <div className="welcome-name">
                {props.progressTitle == null && <h2>Welcome&nbsp;</h2>}
                {props.progressTitle != null && props.progressTitle === "" && (
                  <h2>Welcome&nbsp;</h2>
                )}
                {props.progressTitle != null && props.progressTitle !== "" && (
                  <h2>Hello,&nbsp;{props.progressTitle.split(" ")[0]}</h2>
                )}
              </div>
              {mainData.is_minor == "Y" ? (
                <ProgressBar className="ProgressPan" completed={value} />
              ) : (
                <ProgressBar className="ProgressPan" completed={profileState} />
              )}
            </div>
            {mainData.is_minor !== "Y" ? (
              <>
                {showPanel === "Pan" && (
                  <Pan
                    onPrevious={() => handlePrevious("")}
                    onNext={() => handleNext("Birth")}
                  />
                )}
                {showPanel === "Birth" && (
                  <Birth
                    onPrevious={() => handlePrevious("Pan")}
                    onNext={() => handleNext("AadharAddress")}
                  />
                )}
                {showPanel === "AadharAddress" && (
                  <AadharAddress
                    onPrevious={() => handlePrevious("Birth")}
                    onNext={() => handleNext("Occupation")}
                  />
                )}
                {/* {showPanel === "MaritalStatus" && <MaritalStatus onPrevious={() => handlePrevious("AadharAddress")} onNext={() => handleNext("Occupation")} />} */}
                {showPanel === "Occupation" && (
                  <Occupation
                    onPrevious={() => handlePrevious("AadharAddress")}
                    onNext={() => handleNext("FatcaAll")}
                  />
                )}
                {showPanel === "FatcaAll" && (
                  <FatcaAll
                    onPrevious={() => handlePrevious("Occupation")}
                    onNext={(p = "NomineeDetails") => handleNext(p)}
                  />
                )}
                {showPanel === "FatcaAdd" && (
                  <FatcaAdd
                    onPrevious={() => handlePrevious("FatcaAll")}
                    onNext={() => handleNext("NomineeDetails")}
                  />
                )}
                {showPanel === "NomineeDetails" && (
                  <NomineeDetails
                    onPrevious={(l) => handlePrevious(l)}
                    onNext={() => handleNext("Bank")}
                  />
                )}
                {showPanel === "Bank" && (
                  <Bank
                    onPrevious={() => handlePrevious("NomineeDetails")}
                    onNext={(p) => handleNext(p)}
                    setShowPanel={setShowPanel}
                  />
                )}
                {showPanel === "BankCheque" && (
                  <BankCheque
                    onPrevious={() => handlePrevious("Bank")}
                    onNext={() => handleNext("UploadPan")}
                  />
                )}
                {showPanel === "ConfirmBank" && (
                  <ConfirmBank
                    mainData={mainData}
                    statusData={statusData}
                    setShowPanel={setShowPanel}
                    onPrevious={() => {
                      setShowPanel("Bank");
                      return;
                    }}
                    onNext={() => handleNext("UploadPan")}
                  />
                )}
                {showPanel === "UploadPan" && (
                  <UploadPan
                    onPrevious={() => handlePrevious("ConfirmBank")}
                    onNext={() => handleNext("AadharDocument")}
                  />
                )}
                {showPanel === "AadharDocument" && (
                  <AadharDocument
                    onDocumentSelect={(v) => onDocumentSelect(v)}
                    onPrevious={() => handlePrevious("UploadPan")}
                    onNext={() => handleNext("UploadAadharFront")}
                  />
                )}
                {showPanel === "UploadAadharFront" && (
                  <UploadAadharFront
                    documentName={documentName}
                    onPrevious={(q) => handlePrevious(q)}
                    onNext={(p) => handleNext(p)}
                  />
                )}
                {showPanel === "UploadAadharBack" && (
                  <UploadAadharBack
                    documentName={documentName}
                    onPrevious={(q) => handlePrevious(q)}
                    onNext={() => handleNext("UploadPhoto")}
                  />
                )}
                {showPanel === "UploadPhoto" && (
                  <UploadPhoto
                    onPrevious={() => handlePrevious("UploadAadharBack")}
                    onNext={() => handleNext("UploadVideo")}
                  />
                )}
                {showPanel === "UploadVideo" && (
                  <UploadVideo
                    onPrevious={() => handlePrevious("UploadPhoto")}
                    onNext={() => handleNext("UploadSignature")}
                  />
                )}
                {showPanel === "UploadSignature" && (
                  <UploadSignature
                    onPrevious={() => handlePrevious("UploadVideo")}
                    onNext={() => handleNext("UploadSignature")}
                  />
                )}
              </>
            ) : (
              <>
                {showPanel === "DETAILS" && (
                  <MinorsDetails
                    onPrevious={() => {}}
                    onNext={() => handleNext("OTHERDETAILS")}
                  />
                )}
                {showPanel === "OTHERDETAILS" && (
                  <MinorsOtherDetails
                    onPrevious={() => handlePrevious("DETAILS")}
                    onNext={() => handleNext("Bank")}
                  />
                )}
                {showPanel === "Bank" && (
                  <Bank
                    onPrevious={() => handlePrevious("OTHERDETAILS")}
                    onNext={() => handleNext("UPLOADBANKDETAILS")}
                  />
                )}
                {showPanel === "UPLOADBANKDETAILS" && (
                  <UploadBankDetails
                    onPrevious={() => handlePrevious("Bank")}
                    onNext={() => handleNext("ConfirmBank")}
                  />
                )}
                {showPanel === "ConfirmBank" && (
                  <ConfirmBank
                    onPrevious={() => handlePrevious("UPLOADBANKDETAILS")}
                    onNext={() => handleNext("AGEPROOF")}
                  />
                )}
                {showPanel === "AGEPROOF" && (
                  <AgeProof
                    onPrevious={() => handlePrevious("ConfirmBank")}
                    onNext={() => {
                      if (mainData.guardian_relation == "legal guardian") {
                        handleNext("LEGALGUARDIANPROOF");
                      } else {
                        console.log("here");
                      }
                    }}
                  />
                )}
                {showPanel === "LEGALGUARDIANPROOF" && (
                  <LegalGuardianProof
                    onPrevious={() => handlePrevious("AGEPROOF")}
                    onNext={() => ""}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
const mapStateToProps = (state) => ({
  progressValue: state.progressValue,
  progressTitle: state.progressTitle,
});

export default connect(mapStateToProps)(Profile);
